// -------------------------------------------------------------
//   Main layout
//   Defines basic main styles in layout
// -------------------------------------------------------------

body {
	background-color: #fff;
	color: #6b6b6b;
	font-size: 1rem;
	line-height: 1.75em;
  &:not(.cke_editable) {
    padding-top: 90px;
    @include media-breakpoint-down(xs) {
      padding-top: 125px;
    }
  }
}

.container {
  .md-width {
    margin-left: auto;
    margin-right: auto;
    max-width: 960px;
  }
}

body.page-user, body.page-search404, body.page-search, body.page-sitemap {
  #main {
    .main-wrapper {
      @extend .container;
      padding-top: 30px;
      padding-bottom: 60px;
      @include media-breakpoint-up(xl) {
        max-width: 990px;
      }
    }
  }
}
body.page-search404, body.page-search {
  .container-inline div, .container-inline label {
    display: block;
  }
  #search-form {
    margin: 0 0 30px;
  }
  .search-results {
    li {
      & + li {
        margin-top: 30px;
      }
    }
  }
}

.img-float {
  img {
    float: left;
    margin-right: 30px;
    margin-bottom: 30px;
    @include media-breakpoint-down(sm) {
      float: none;
      margin-right: 0;
    }
  }
}

.caracts {
  ul {
    margin: 0 0 45px;;
    padding: 0;
    list-style: none;
    li {
      h5 {
        margin: 0;
      }
      & + li {
        margin-top: 10px;
      }
    }
  }
}

.content-full {
  clear: both;
}

.iframe-embed {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  padding-top: percentage(9 / 16);
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.cta-fixed {
  a {
    display: block;
    position: fixed;
    z-index: 300;
    top: 200px;
    right: 0;
    padding: 7px 12px;
    border-radius: 4px 0 0 4px;
    color: #fff;
    font-size: 0;
    text-decoration: none;
    @include transition(all .3s);
    img, span {
      display: inline-block;
      vertical-align: middle;
    }
    span {
      padding-left: 15px;
      width: 170px;
      font-size: 0.9rem;
      white-space: nowrap;
      @include transition(all .35s);
      @include media-breakpoint-down(md) {
        opacity: 0;
        max-width: 0;
      }
    }
    & + a {
      margin-top: 2px;
    }
    &:nth-child(1) {
      background-color: map-get($theme-colors, primary);
      color: #fff;
      &:hover {
        background-color: darken(map-get($theme-colors, primary), 10%);
        color: #fff;
      }
    }
    &:nth-child(2) {
      top: 250px;
      background-color: map-get($theme-colors, secondary);
      color: #fff;
      &:hover {
        background-color: darken(map-get($theme-colors, secondary), 10%);
        color: #fff;
      }
    }
    &.scrolled {
      span {
        padding-left: 0;
        width: 0;
        opacity: 0;
      }
    }
    &:hover {
      span {
        padding-left: 15px;
        width: 170px;
        opacity: 1;
      }
    }
  }
}

.back-to-list {
  padding: 0;
  text-align: right;
  .btn {
    padding: 10px 15px;
  }
}
