.node-type-produits {
  #main {
    @include media-breakpoint-down(md) {
      overflow: hidden;
    }
  }
  .menu-catalogue {
    @include media-breakpoint-up(lg) {
      padding-top: 85px;
    }
  }
  .produit-details {
    color: #696969;
    font-size: 1rem;
    font-family: $ff-headings;
    font-weight: 400;
    line-height: 1.5em;
    p, ul, ol {
      margin: 0 0 25px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .wrapper {
      position: relative;
      padding: 0 40px 0 25px;
      @include media-breakpoint-up(lg) {
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: -15px;
          right: 0;
          height: 1px;
          background-color: #e6e6e6;
        }
      }
      @include media-breakpoint-down(md) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .fiche {
      padding-top: 40px;
      border-bottom: 1px solid #e6e6e6;
      .back {
        text-align: right;
        @include media-breakpoint-down(md) {
          text-align: left;
        }
        a {
          color: #111;
          font-weight: 500;
          text-decoration: none;
          @include transition(all .2s);
          .fa {
            margin-right: 5px;
          }
          &:hover {
            color: map-get($theme-colors, primary);
          }
        }
      }
    }
    .content {
      padding: 55px 40px 60px;
      @include media-breakpoint-down(lg) {
        padding-left: 15px;
        padding-right: 15px;
      }
      h1 {
        margin: 0 0 10px;
        color: #111;
      }
      .btn {
        padding-left: 20px;
        padding-right: 20px;
      }
      .desc {
        margin-top: 40px;
      }
    }
    .content-product {
      padding-top: 35px;
      .container {
        padding: 0;
      }
    }
    .paragraphs-avantages {
      font-family: $ff-body;
      line-height: 1.75em;
    }
  }
}
