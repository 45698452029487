// -------------------------------------------------------------
//   Paragraphs item cta
// -------------------------------------------------------------

.paragraphs-cta {
  .evidence {
    font-size: 0;
  }
	.content, .cta {
		display: inline-block;
		vertical-align: middle;
    font-size: 1.5rem;
    line-height: 1.35em;
    @include media-breakpoint-down(md) {
      display: block;
    }
	}
	.content {
		padding-right: 30px;
		width: 75%;
    @include media-breakpoint-down(md) {
      padding-right: 0;
      width: 100%;
    }
	}
	.cta {
		padding-left: 15px;
		width: 25%;
    @include media-breakpoint-down(md) {
      margin-top: 30px;
      padding-left: 0;
      width: 100%;
    }
	}
}
