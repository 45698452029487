.node-type-webform {
  .banner {
    height: 480px;
    @include media-breakpoint-down(sm) {
      height: 380px;
    }
    @include media-breakpoint-down(xs) {
      height: 250px;
    }
    .bg {
      opacity: .75;
    }
  }
  #main {
    color: #656565;
    font-family: $ff-headings;
    line-height: 1.5em;
    a {
      color: map-get($theme-colors, primary);
      &:hover {
        color: map-get($theme-colors, primary);
      }
    }
  }
  .node-webform {
    padding-top: 40px;
    padding-bottom: 165px;
    form {
      margin-top: 65px;
      padding-top: 40px;
      background-color: map-get($theme-colors, primary);
      > * {
        margin-left: auto;
        margin-right: auto;
        max-width: 750px;
      }
      h2, h3, h4, h5, h6 {
        margin-bottom: 15px;
        color: #fff;
      }
      label {
        color: #fff;
      }
      .description {
        color: #fff;
        font-size: 0.875rem;
        line-height: 1.3em;
      }
      .form-managed-file {
        margin-bottom: 10px;
        .form-file {
          display: inline-block;
          vertical-align: middle;
          color: #fff;
        }
        input[type=submit] {
          display: inline-block;
          vertical-align: middle;
          margin: 0;
          padding: 10px 30px;
        }
      }
      .form-actions {
        margin-top: 40px;
        margin-bottom: -30px;
        text-align: center;
      }
    }
  }
}
