// Breadcrumb
// =============================================================================

.breadcrumb {
  padding: 24px 0;
  color: #acacac;
  font-family: $ff-headings;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: normal;
  @include media-breakpoint-down(xs) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  a {
    color: #acacac;
  }
}
