// -------------------------------------------------------------
//   Paragraphs item galerie
// -------------------------------------------------------------

.paragraphs-galerie {
  .swiper-container {
    width: 100%;
    height: 645px;
    @include media-breakpoint-down(sm) {
      height: 365px;
    }
    .swiper-slide {
      height: 600px;
      background-color: #000;
      text-align: center;
      overflow: hidden;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: auto;
        -ms-transform: translate(-50%,-50%);
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
      }
      @include media-breakpoint-down(sm) {
        height: 320px;
      }
    }
  }

  // Navigation
  .swiper-button-next, .swiper-button-prev {
    position: absolute;
    z-index: 10;
    top: 50%;
    border: 1px solid #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    cursor: pointer;
    background-image: none !important;
    @include transition(all .3s);
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      color: #fff;
      font-family: "FontAwesome";
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 48px;
      text-align: center;
      @include transition(all .3s);
    }
    &:hover {
      background-color: #fff;
      &:after {
        color: #a7a7a7;
      }
    }
  }
  .swiper-button-prev {
    left: 4.7%;
    right: auto;
    &:after {
      content: "\f104";
    }
  }
  .swiper-button-next {
    left: auto;
    right: 4.7%;
    &:after {
      content: "\f105";
    }
  }

  // Pagination
  .swiper-pagination {
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    padding: 19px 0 20px;
    width: 100%;
    font-size: 0;
    line-height: 0;
    text-align: center;
    @include transition(all .3s);
  }
  .swiper-pagination-bullet {
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #dadada;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background-color: transparent;
    opacity: 1;
  }
  .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 12px;
  }
  .swiper-pagination-bullet-active {
    background-color: #a7a7a7;
  }
}
