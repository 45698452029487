.pager {
  margin: 50px 0 0;
  padding: 0;
  list-style: 0;
  font-size: 0;
  line-height: 0;
  text-align: center;
  li {
    display: inline-block;
    vertical-align: middle;
    color: #1a1a1a;
    font-family: $ff-headings;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.65em;
    a {
      display: block;
      padding: 5px 10px;
      color: #1a1a1a;
      text-decoration: none;
    }
    &.pager-current {
      padding: 5px 10px;
      color: map-get($theme-colors, primary);
    }
    & + li {
      margin-left: 5px;
    }
    &:hover {
      a {
        color: map-get($theme-colors, primary);
      }
    }
  }
}
