// -------------------------------------------------------------
//   Homepage
//   Homepage specific styles
// -------------------------------------------------------------

.leaderboard {
  position: relative;
  height: 610px;
  background-color: #000;
  @include media-breakpoint-down(xs) {
    height: auto;
  }
  .title {
    @include media-breakpoint-up(sm) {
      position: absolute;
      z-index: 10;
      top: 110px;
      left: 0;
      width: 100%;
    }
    @include media-breakpoint-down(xs) {
      position: relative;
      z-index: 10;
      padding: 30px 0;
    }
    h1, .subtitle {
      max-width: 600px;
      color: #fff;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    }
    h1 {
      margin-bottom: 25px;
      @include media-breakpoint-down(xs) {
        margin-bottom: 15px;
      }
    }
    .subtitle {
      margin-bottom: 35px;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: normal;
      @include media-breakpoint-down(xs) {
        margin-bottom: 10px;
      }
    }
    .ctas {
      margin-left: -10px;
      font-size: 0;
      a {
        display: inline-block;
        vertical-align: middle;
        margin: 10px;
      }
      a:not([class*="btn"]) {
        color: #fff;
        font-family: $ff-headings;
        font-size: 1rem;
        font-weight: 500;
        line-height: normal;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      .btn {
        position: relative;
        padding-left: 20px;
        padding-right: 50px;
        @include media-breakpoint-down(xs) {
          padding-left: 15px;
          padding-right: 45px;
        }
        &:after {
          content: "\f061";
          position: absolute;
          top: 50%;
          right: 20px;
          margin-top: -10px;
          font-family: "FontAwesome";
          font-weight: 400;
        }
      }
    }
  }
  .bg, .video-background {
    // opacity: .9;
  }
  .bg-mobile {
    z-index: 1;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  /* .video-background {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    @include media-breakpoint-down(sm) {
      display: none;
    }
    iframe {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      opacity: 0;
      @include transition(opacity .5s);
      &.active {
        opacity: 1;
      }
    }
  } */

  .video-background {
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    overflow: hidden;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  #video-foreground, .video-background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  
  @media (min-aspect-ratio: 16/9) {
    #video-foreground {
      top: -100%;
      height: 300%;
    }
  }
  @media (max-aspect-ratio: 16/9) {
    #video-foreground {
      left: -100%;
      width: 300%;
    }
  }

  /* .video-background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    iframe {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      margin: auto;
      opacity: 0;
      transition: opacity .5s;
      pointer-events: none;
      &.active {
        opacity: 1;
      }
    }
  } */

  @include media-breakpoint-up(md) {
    .bg-mobile {
      display: none;
    }
  }
}

.gammes {
  background-color: #f3f3f3;
  @include media-breakpoint-down(xs) {
    padding-top: 30px;
  }
  .wrapper {
    position: relative;
    z-index: 5;
    margin-top: -90px;
    background-color: #fff;
    box-shadow: 0px 35px 49px 0px rgba(0, 0, 0, 0.1);
    @include media-breakpoint-down(xs) {
      margin-top: 0;
    }
  }
  .gamme {
    padding: 50px 80px;
    color: map-get($theme-colors, primary);
    font-family: $ff-headings;
    line-height: 1.5em;
    @include media-breakpoint-down(md) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @include media-breakpoint-down(sm) {
      padding-left: 15px;
      padding-right: 15px;
    }
    @include media-breakpoint-down(xs) {
      & + .gamme {
        border-top: 1px solid #e5e5e5;
      }
    }
    h3 {
      color: map-get($theme-colors, primary);
      font-weight: 700;
      line-height: 1.15em;
      sup {
        text-transform: uppercase;
      }
    }
    p {
      margin: 0 0 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    ul {
      margin: 0 0 30px;
      padding: 0;
      list-style: none;
      li {
        position: relative;
        padding-left: 10px;
        &:before {
          content: "\f105";
          position: absolute;
          top: 0;
          left: 0;
          font-family: "FontAwesome";
        }
      }
    }
    &.opticube {
      color: #898a8a;
      h3 {
        color: map-get($theme-colors, blue);
      }
      .btn {
        background-color: map-get($theme-colors, blue);
        border-color: map-get($theme-colors, blue);
        &:hover {
          background-color: darken(map-get($theme-colors, blue), 10%);
          border-color: darken(map-get($theme-colors, blue), 10%);
        }
      }
    }
    &:nth-child(even) {
      border-left: 1px solid #e5e5e5;
      @include media-breakpoint-down(xs) {
        border-left: 0;
      }
    }
  }
}

.steps {
  .schemes {
    padding-top: 60px;
    background-color: #f3f3f3;
    text-align: center;
    h2 {
      color: map-get($theme-colors, blue);
      font-size: 2.25rem;
      font-weight: 300;
      line-height: 1.05em;
      text-transform: uppercase;
      span {
        color: map-get($theme-colors, orange);
      }
    }
    img {
      margin-bottom: -10px;
    }
  }
  .carousel {
    padding-top: 50px;
    background-image: url(../images/triangle.svg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 710px auto;
  }

  .steps-carousel {
    position: relative;
  }
  .swiper-container {
    width: 100%;
    height: 315px;
    @include media-breakpoint-down(md) {
      height: 260px;
    }
    @include media-breakpoint-down(sm) {
      height: 315px;
    }
    @include media-breakpoint-down(xs) {
      height: auto;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 38px;
      right: 38px;
      height: 2px;
      background-color: #e0e0e0;
      @include media-breakpoint-down(xs) {
        left: 0;
        right: 0;
      }
    }
    .swiper-slide {
      padding-left: 38px;
      color: #898a8a;
      font-family: $ff-headings;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.65em;
      @include media-breakpoint-down(xs) {
        padding-left: 0;
      }
      .inner {
        position: relative;
        border-left: 2px solid #a7a9ac;
        padding: 90px 12px 35px 50px;
        height: 315px;
        @include transition(all .3s);
        @include media-breakpoint-down(md) {
          height: 260px;
        }
        @include media-breakpoint-down(sm) {
          height: 315px;
        }
        @include media-breakpoint-down(xs) {
          border-left: 0;
          padding-left: 0;
          padding-right: 0;
          height: auto;
        }
      }
      .step-number {
        display: inline-block;
        position: absolute;
        top: 0;
        left: -38px;
        color: #a7a9ac;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: normal;
        opacity: 0;
        @include transition(all .3s);
        @include media-breakpoint-down(xs) {
          left: 0;
        }
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          -ms-transform: translate(-50%,-50%);
          -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
        }
        svg {
          display: block;
        }
        .stroke {
          @include transition(all .3s);
        }
      }
      .title {
        margin: 0;
        color: #a7a9ac;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: normal;
        @include transition(all .3s);
      }
      &.swiper-slide-active {
        .inner {
          border-left-color: map-get($theme-colors, orange);
        }
        .step-number {
          color: map-get($theme-colors, orange);
          .stroke {
            fill: map-get($theme-colors, orange) !important;
          }
        }
        .title {
          color: map-get($theme-colors, orange);
        }
      }
      &.swiper-slide-active, &.swiper-slide-next {
        .step-number {
          opacity: 1;
        }
      }
    }
  }

  // Navigation
  .swiper-button-next, .swiper-button-prev {
    position: absolute;
    z-index: 10;
    top: 115px;
    border: 1px solid #898a8a;
    border-radius: 50%;
    margin: 0;
    width: 50px;
    height: 50px;
    cursor: pointer;
    background-image: none !important;
    outline: 0 !important;
    @include transition(all .3s);
    @include media-breakpoint-down(lg) {
      top: auto;
      bottom: 0;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      color: #898a8a;
      font-family: "FontAwesome";
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 48px;
      text-align: center;
      @include transition(all .3s);
    }
    &:hover {
      background-color: #898a8a;
      &:after {
        color: #fff;
      }
    }
  }
  .swiper-button-prev {
    left: -80px;
    right: auto;
    @include media-breakpoint-down(lg) {
      left: 0;
    }
    &:after {
      content: "\f104";
    }
  }
  .swiper-button-next {
    left: auto;
    right: -80px;
    @include media-breakpoint-down(lg) {
      right: 0;
    }
    &:after {
      content: "\f105";
    }
  }

  // Pagination
  .swiper-pagination {
    position: relative;
    margin: 20px 0 0;
    padding: 19px 0 20px;
    font-size: 0;
    line-height: 0;
    text-align: center;
    @include transition(all .3s);
  }
  .swiper-pagination-bullet {
    display: inline-block;
    vertical-align: middle;
    margin: 0 12px;
    border: 1px solid #dadada;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background-color: transparent;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background-color: #a7a7a7;
  }
}

.about {
  padding-top: 60px;
  .title {
    .inner {
      padding-left: 80px;
      @include media-breakpoint-down(md) {
        padding-left: 45px;
      }
      @include media-breakpoint-down(sm) {
        padding-left: 15px;
      }
    }
    h2 {
      margin-bottom: 30px;
      color: map-get($theme-colors, secondary);
      font-size: 1.9rem;
      font-weight: 400;
      line-height: normal;
    }
  }
  .text {
    padding-top: 50px;
    padding-bottom: 35px;
    background-color: map-get($theme-colors, secondary);
    color: #fff;
    font-family: $ff-headings;
    font-size: 1.15rem;
    line-height: 1.5em;
  }
  .img-wrapper {
    @include media-breakpoint-down(sm) {
      display: none;
      padding-left: 15px;
      padding-right: 15px;
    }
    img {
      margin-top: -160px;
      margin-left: -5px;
      @include media-breakpoint-down(sm) {
        margin: 0 0 30px;
      }
    }
  }
  .content {
    padding: 0 15px 0 80px;
    @include media-breakpoint-down(md) {
      padding-left: 45px;
    }
    @include media-breakpoint-down(sm) {
      padding-left: 15px;
    }
    @include media-breakpoint-down(xs) {
      padding-left: 0;
      padding-right: 0;
    }
    .btn {
      padding: 10px 15px;
      font-size: 0.75rem;
      font-weight: 700;
      letter-spacing: .5px;
      line-height: normal;
      text-transform: uppercase;
    }
  }
}

.references {
  padding-top: 65px;
  padding-bottom: 80px;
  h2 {
    color: #252525;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: normal;
  }
  .view-all {
    position: absolute;
    top: 0;
    right: 15px;
    @include media-breakpoint-down(md) {
      position: relative;
      top: auto;
      left: auto;
      margin-top: 10px;
      text-align: center;
    }
    a {
      color: map-get($theme-colors, primary);
      font-size: 0.9rem;
      font-weight: 400;
      line-height: normal;
      .fa {
        margin-left: 10px;
      }
    }
  }
  .view-content {
    @include make-row();
    -webkit-box-pack: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important;
    margin-right: -7.5px;
    margin-left: -7.5px;
    .views-row {
      @include make-col-ready();
      @include make-col(4);
      padding-right: 7.5px;
      padding-left: 7.5px;
      @include media-breakpoint-down(md) {
        @include make-col(6);
      }
      @include media-breakpoint-down(xs) {
        @include make-col(12);
      }
      .item-reference {
        @extend .content-center;
      }
    }
  }
}

.actualites {
  padding-top: 40px;
  padding-bottom: 65px;
  background-color: #f8f8f8;
  h2 {
    color: #252525;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
  .view-content {
    @include make-row();
    margin-right: -5px;
    margin-left: -5px;
    .views-row {
      @include make-col-ready();
      @include make-col(3);
      padding-right: 5px;
      padding-left: 5px;
      @include media-breakpoint-down(md) {
        @include make-col(6);
      }
      @include media-breakpoint-down(xs) {
        @include make-col(12);
      }
      &:first-child {
        @include make-col(6);
        @include media-breakpoint-down(md) {
          @include make-col(12);
        }
        .item-actualite {
          @extend .big;
        }
      }
    }
  }
  .view-all {
    margin: 15px 0 0;
    text-align: center;
    a {
      color: map-get($theme-colors, darkblue);
      font-size: 0.9rem;
      font-weight: 400;
      line-height: normal;
      .fa {
        margin-left: 10px;
      }
    }
  }
}
