// -------------------------------------------------------------
//   Types
// -------------------------------------------------------------

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
	margin: 0 0 25px;
  color: #656565;
  line-height: 1.1em;
  font-family: $ff-headings;
  font-weight: 500;
  &:last-child {
    margin-bottom: 0;
  }
  strong {
    color: map-get($theme-colors, primary);
    font-weight: 500;
  }
}
h1,.h1{
  margin-bottom: 15px;
	font-size: 2.25rem;
  line-height: 1.1em;
  @include media-breakpoint-down(xs) {
    font-size: 1.9rem;
  }
}
h2,.h2 {
  margin-bottom: 25px;
	font-size: 1.75rem;
  line-height: 1.3em;
}
h3,.h3 {
  margin-bottom: 25px;
	font-size: 1.5rem;
  line-height: 1.5em;
}
h4,.h4 {
  margin-bottom: 20px;
	font-size: 1.25rem;
  line-height: 2em;
}
h5,.h5 {
	font-size: 1rem;
}
h6,.h6 {
	font-size: 0.75rem;
}

p, ul, ol {
	margin: 0 0 30px;
  &:last-child {
    margin-bottom: 0;
  }
}

blockquote {
  margin: 60px 0;
  border-left: 4px solid map-get($theme-colors, primary);
  padding: 15px 50px 20px 60px;
  color: #6b6b6b;
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 300;
  line-height: 1.5em;
  @include media-breakpoint-down(md) {
    padding-left: 45px;
    padding-right: 0;
  }
  @include media-breakpoint-down(sm) {
    padding-left: 30px;
  }
  @include media-breakpoint-down(xs) {
    padding-left: 15px;
  }
  strong {
    color: map-get($theme-colors, primary);
    font-weight: 300;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

a {
  color: #6b6b6b;
  text-decoration: underline;
  &:hover {
    color: #6b6b6b;
    text-decoration: none;
  }
}

.lead {
  margin: 0 0 70px;
  color: #303030;
  font-family: $ff-headings;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.5em;
  &:last-child {
    margin-bottom: 0;
  }
}

.evidence {
  margin: 0 0 55px;
  padding: 60px 60px 65px;
  background-color: map-get($theme-colors, primary);
  color: #fff;
  font-family: $ff-headings;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.35em;
  @include media-breakpoint-down(md) {
    padding-left: 45px;
    padding-right: 45px;
  }
  @include media-breakpoint-down(sm) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @include media-breakpoint-down(xs) {
    padding-left: 15px;
    padding-right: 15px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.red {
    background-color: map-get($theme-colors, primary);
  }
  &.green {
    background-color: map-get($theme-colors, secondary);
  }
}

.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
