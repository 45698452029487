.page-references, .page-referencias {
  .banner {
    .bg {
      // opacity: .9;
    }
  }
  .view {
    @include make-container();
    @include media-breakpoint-down(xl) {
      max-width: 1200px;
    }
    @include media-breakpoint-down(lg) {
      max-width: 990px;
    }
    @include media-breakpoint-down(md) {
      max-width: 720px;
    }
    @include media-breakpoint-down(sm) {
      max-width: 540px;
    }
    padding-top: 25px;
    padding-bottom: 55px;
  }
  .view-content {
    @include make-row();
    margin-right: -7.5px;
    margin-left: -7.5px;
    .views-row {
      @include make-col-ready();
      @include make-col(4);
      padding-right: 7.5px;
      padding-left: 7.5px;
      @include media-breakpoint-down(md) {
        @include make-col(6);
      }
      @include media-breakpoint-down(xs) {
        @include make-col(12);
      }
    }
  }
}
