// -------------------------------------------------------------
//   Paragraphs item texte
// -------------------------------------------------------------

.paragraphs-texte {
  padding-top: 30px;
  padding-bottom: 60px;
	.row {
		margin-bottom: 30px;
		&:last-child {
			margin-bottom: 0;
		}
		.col-md-6 {
			&:first-child {
				padding-right: 40px;
				@include media-breakpoint-down(md) {
					padding-right: 15px;
				}

				@include media-breakpoint-down(sm) {
					margin-bottom: 30px;
				}
			}
			&:last-child {
				padding-left: 40px;
				@include media-breakpoint-down(md) {
					padding-left: 15px;
				}
			}
		}
	}
	img {
		max-width: 100%;
    height: auto !important;
	}
}
