.menu-catalogue {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #f8f8f8;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;
    width: 9999px;
    background-color: #f8f8f8;
  }
  @include media-breakpoint-down(md) {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 100%;
      bottom: 0;
      width: 9999px;
      background-color: #f8f8f8;
    }
  }
  h2 {
    color: #000;
    font-size: 1.5rem;
    line-height: 1.65em;
    @include media-breakpoint-down(md) {
      position: relative;
      margin-bottom: 0;
      border-radius: 4px;
      padding: 15px 25px;
      padding-right: 65px;
      background-color: map-get($theme-colors, primary);
      color: #fff;
      cursor: pointer;
      @include transition(all .3s);
      &:after {
        content: "+";
        position: absolute;
        top: 50%;
        right: 25px;
        margin-top: -20px;
        @include transition(all .3s);
      }
      &:hover {
        background-color: darken(map-get($theme-colors, primary), 10%);
      }
      &.show {
        margin-bottom: 25px;
        &:after {
          content: "-";
        }
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    color: #181818;
    font-family: $ff-headings;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.15em;
    @include media-breakpoint-up(lg) {
      display: block !important;
      height: auto !important;
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
    li {
      position: relative;
      padding-left: 15px;
      @include transition(all .3s);
      &:before {
        content: "\f105";
        position: absolute;
        top: 0;
        left: 0;
        font-family: "FontAwesome";
      }
      a {
        display: block;
        color: #181818;
        font-weight: 700;
        text-decoration: none;
        @include transition(all .3s);
        span {
          display: block;
          color: #6b6b6b;
          font-weight: 400;
        }
      }
      &:hover, &.active {
        color: map-get($theme-colors, primary);
        a {
          color: map-get($theme-colors, primary);
        }
      }
      & + li {
        margin-top: 20px;
      }
    }
  }
}

.page-gamme-solaire-thermique, .page-solar-thermal-range, .page-gama-solar-termico {
  .banner {
    height: 300px;
    @include media-breakpoint-down(xs) {
      height: 250px;
    }
    .container .md-width {
      max-width: none;
    }
    .bg {
      /* opacity: .8; */
    }
  }
  .view-gamme-solaire-thermique {
    overflow: hidden;
  }
  .catalogue {
    padding-top: 10px;
    padding-bottom: 105px;
  }
  .view-content {
    @include make-row();
    margin-right: -10px;
    margin-left: -10px;
    .views-row {
      @include make-col-ready();
      @include make-col(6);
      padding-right: 10px;
      padding-left: 10px;
      @include media-breakpoint-down(sm) {
        @include make-col(12);
      }
    }
  }
}
