.node-type-opticube {
  .banner {
    height: 640px;
    @include media-breakpoint-down(sm) {
      height: 380px;
    }
    @include media-breakpoint-down(xs) {
      height: 250px;
    }
    &:after {
      display: none;
    }
    h1, .subtitle {
      color: #fff;
    }
    h1 {
      margin-bottom: 25px;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
      &:last-child {
        margin-bottom: 0;
      }
    }
    .subtitle {
      max-width: 350px;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: normal;
    }
  }

  .main-wrapper {
    padding-bottom: 60px;
  }

  h1,.h1,
  h2,.h2,
  h3,.h3,
  h4,.h4,
  h5,.h5,
  h6,.h6 {
    color: map-get($theme-colors, regularblue);
    strong {
      color: map-get($theme-colors, lightblue);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  h2,.h2 {
    margin-bottom: 30px;
  }

  .steps {
    padding-bottom: 25px;
    .schemes {
      background-color: transparent;
      h2 {
        margin-bottom: 40px;
      }
    }
    .carousel {
      background-image: none;
    }
  }

  .paragraphs-texte {
    padding-bottom: 50px;
    .btn {
      padding: 20px 30px;
      .fa {
        margin-left: 15px;
      }
    }
  }

  .evidence {
    padding: 75px 110px;
    background-color: map-get($theme-colors, lightblue);
    @include media-breakpoint-down(lg) {
      padding-left: 60px;
      padding-right: 60px;
    }
    @include media-breakpoint-down(md) {
      padding-left: 45px;
      padding-right: 45px;
    }
    @include media-breakpoint-down(sm) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @include media-breakpoint-down(xs) {
      padding-left: 15px;
      padding-right: 15px;
    }
    .btn {
      padding-left: 20px;
    }
  }

  .paragraphs-cta {
    .content {
      padding-right: 15px;
      width: 55%;
      @include media-breakpoint-down(md) {
        padding-right: 0;
        width: 100%;
      }
    }
    .cta {
      padding-left: 15px;
      width: 45%;
      text-align: right;
      @include media-breakpoint-down(md) {
        margin-top: 30px;
        padding-left: 0;
        text-align: left;
        width: 100%;
      }
    }
  }

  .paragraphs-avantages {
    padding-top: 30px;
    padding-bottom: 30px;
    .md-width {
      max-width: none;
    }
    .avantages {
      padding: 30px 0 15px;
      background-color: transparent;
      color: map-get($theme-colors, secondary);
      font-family: $ff-headings;
      line-height: normal;
      h2 {
        margin: 0 0 50px;
        color: map-get($theme-colors, regularblue);
        text-align: center;
      }
      ul {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important;
        li {
          -ms-flex-preferred-size: 0;
          flex-basis: 0;
          -ms-flex-positive: 1;
          flex-grow: 1;
          max-width: 100%;
          margin: -1px 0 0 -1px;
          border: 1px solid #e6e6e6;
          padding: 65px 20px 60px;
          text-align: center;
          @include media-breakpoint-down(md) {
            -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
          }
          @include media-breakpoint-down(sm) {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
          }
          @include media-breakpoint-down(xs) {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            padding-left: 15px;
            padding-right: 15px;
          }
          &:before {
            content: "\f05d";
            display: block;
            margin: 0 auto 15px;
            position: relative;
            top: auto;
            left: auto;
            font-size: 1.9rem;
          }
        }
      }
    }
  }

  .kits {
    padding-top: 25px;
    padding-bottom: 40px;
    .title {
      margin-bottom: 40px;
      text-align: center;
    }
    .kit {
      @include media-breakpoint-down(sm) {
        & + .kit {
          margin-top: 30px;
        }
      }
      h1,.h1,
      h2,.h2,
      h3,.h3,
      h4,.h4,
      h5,.h5,
      h6,.h6 {
        color: #656565;
        &:last-child {
          margin-bottom: 0;
        }
      }
      h3,.h3 {
        margin-bottom: 30px;
      }
      .img-wrapper {
        position: relative;
      }
      .content {
        position: relative;
        .inner {
          padding: 30px 100px 30px 40px;
          max-width: 480px;
          background-color: #f8f8f8;
          @include media-breakpoint-down(md) {
            padding-left: 30px;
            padding-right: 30px;
          }
          @include media-breakpoint-down(sm) {
            max-width: none;
          }
          @include media-breakpoint-down(xs) {
            padding-left: 15px;
            padding-right: 15px;
          }
        }
      }
      @include media-breakpoint-up(md) {
        &.posDroite {
          .img-wrapper {
            left: 50%;
          }
          .content {
            right: 50%;
            .inner {
              float: right;
            }
          }
        }
      }
    }
  }

  .references {
    margin-top: -100px;
    padding-top: 155px;
    padding-bottom: 45px;
    background-color: #f3f3f3;
    text-align: center;
    h2 {
      margin-bottom: 0;
      font-size: 1.75rem;
      font-weight: 500;
      line-height: 1.3em;
    }
    p {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .item-reference h3 {
      color: #fff;
    }
    .view-all {
      position: relative;
      top: auto;
      right: auto;
      margin-top: 10px;
    }
  }
}
