
// Button
// =============================================================================

.btn {
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 15px 25px;
  box-shadow: none !important;
  font-family: $ff-headings;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.15em;
  white-space: normal;
  text-decoration: none !important;
  @include transition(all .3s);
  &.btn-primary, &.btn-default, &.btn_rouge {
    background-color: map-get($theme-colors, primary);
    border-color: map-get($theme-colors, primary);
    color: #fff;
    &:hover {
      background-color: darken(map-get($theme-colors, primary), 10%);
      border-color: darken(map-get($theme-colors, primary), 10%);
      color: #fff;
    }
  }
  &.btn-secondary, &.btn_vert {
    background-color: map-get($theme-colors, secondary);
    border-color: map-get($theme-colors, secondary);
    color: #fff;
    &:hover {
      background-color: darken(map-get($theme-colors, secondary), 10%);
      border-color: darken(map-get($theme-colors, secondary), 10%);
      color: #fff;
    }
  }
  &.btn_bleu {
    background-color: map-get($theme-colors, blue);
    border-color: map-get($theme-colors, blue);
    color: #fff;
    &:hover {
      background-color: darken(map-get($theme-colors, blue), 10%);
      border-color: darken(map-get($theme-colors, blue), 10%);
      color: #fff;
    }
  }
  &.btn_regularblue {
    background-color: map-get($theme-colors, regularblue);
    border-color: map-get($theme-colors, regularblue);
    color: #fff;
    &:hover {
      background-color: darken(map-get($theme-colors, regularblue), 10%);
      border-color: darken(map-get($theme-colors, regularblue), 10%);
      color: #fff;
    }
  }
  &.btn-black {
    background-color: #151515;
    border-color: #151515;
    color: #fff;
    &:hover {
      background-color: darken(#151515, 10%);
      border-color: darken(#151515, 10%);
      color: #fff;
    }
  }
  &.withArrow, &.btn1, &.btn2 {
    position: relative;
    padding-right: 45px;
    &:after {
      content: "\f105";
      position: absolute;
      top: 50%;
      right: 25px;
      margin-top: -10px;
      font-family: "FontAwesome";
      font-weight: 400;
    }
  }
}

input[type="submit"] {
  @extend .btn;
  @extend .btn-black;
  padding: 20px 35px;
  cursor: pointer;
}
