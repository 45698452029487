.view-filters {
  margin-bottom: 5px;
  .views-exposed-widget {
    font-size: 0;
    line-height: 0;
  }
  label, .views-widget {
    display: inline-block;
    vertical-align: middle;
  }
  label {
    margin: 0 25px 0 0;
    padding: 6px 0;
    color: #6b6b6b;
    font-family: $ff-body;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75em;
  }
  .form-item {
    margin: 0;
  }
  .form-select, .form-text, .form-textarea {
    display: block;
    margin: 0;
    border: 1px solid #e6e6e6;
    border-radius: 0;
    padding: 0 15px;
    width: 100%;
    height: 40px;
    background-color: #fff;
    color: map-get($theme-colors, primary);
    font-size: 1rem;
    font-family: $ff-body;
    font-weight: 400;
    line-height: normal;
  }
}
