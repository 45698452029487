// -------------------------------------------------------------
//   Banner
// -------------------------------------------------------------

.banner {
  position: relative;
  height: 380px;
  background-color: #000;
  @include media-breakpoint-down(xs) {
    height: 250px;
  }
  &:after {
    content: "";
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 55%;
    background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 );
    opacity: .5;
    opacity: 0;
  }
  .title {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 0;
    width: 100%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  h1, .subtitle {
    color: #fff;
    @include media-breakpoint-down(xs) {
      padding-right: 60px;
    }
  }
  .subtitle {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: normal;
  }
  .breadcrumb {
    padding: 15px 0 0;
    color: #fff;
    a {
      color: #fff;
    }
  }
}
