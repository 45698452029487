.item-actualite {
  display: block;
  position: relative;
  margin: 15px 0;
  height: 415px;
  background-color: #fff;
  text-decoration: none;
  @include media-breakpoint-down(xs) {
    height: auto;
  }
  .img-wrapper {
    position: relative;
    height: 265px;
    background-color: #000;
    overflow: hidden;
    .bg {
      @include transition(all .3s);
    }
  }
  .content {
    padding: 30px;
    color: #a7a7a7;
    font-family: $ff-headings;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: normal;
    @include media-breakpoint-down(lg) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @include media-breakpoint-down(xs) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  p {
    margin: 0 0 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .date {
    margin: 0;
    letter-spacing: .5px;
    text-transform: uppercase;
  }
  h3 {
    margin: 0;
    color: #2d2d2d;
    font-size: 1.15rem;
    font-weight: 400;
    line-height: normal;
    @include transition(all .3s);
  }
  .link {
    font-size: 1rem;
    text-decoration: underline;
  }
  .link, .summary {
    display: none;
  }
  &:hover {
    .img-wrapper {
      .bg {
        -ms-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
    h3 {
      color: map-get($theme-colors, primary);
    }
    .link {
      text-decoration: none;
    }
  }
  &.big {
    background-color: transparent;
    border: 1px solid #e6e6e6;
    @include media-breakpoint-down(xs) {
      height: 415px;
    }
    &:after {
      content: "";
      position: absolute;
      z-index: 5;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: -webkit-linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
      background: -o-linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
      background: linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
      opacity: .8;
    }
    .img-wrapper {
      height: 100%;
    }
    .content {
      position: absolute;
      z-index: 10;
      bottom: 10px;
      left: 20px;
      width: calc(100% - 40px);
      max-width: 425px;
      color: #fff;
      @include media-breakpoint-down(xs) {
        left: 0;
        width: 100%;
      }
    }
    h3 {
      margin-bottom: 20px;
      color: #fff;
      font-size: 1.5rem;
    }
    .link, .summary {
      display: block;
    }
  }
}
