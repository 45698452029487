// -------------------------------------------------------------
//   Main header
//   Header styles
// -------------------------------------------------------------

.main-header {
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;

  .logo {
    float: left;
    margin-left: -15px;
    padding: 15px;
    width: 100%;
    max-width: 160px;
    @include media-breakpoint-down(xs) {
      float: none;
      display: block;
      margin: 0 auto;
    }
  }

  &.headroom {
    /* will-change: transform; */
    @include transition(transform 0.3s);
  }
  &.headroom--pinned {
    /* -ms-transform: translateY(0%);
    -webkit-transform: translateY(0%);
    transform: translateY(0%); */
  }
  &.headroom--unpinned {
    /* -ms-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%); */
  }
}
