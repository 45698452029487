// -------------------------------------------------------------
//   Navigation
// -------------------------------------------------------------
#nav {
  font-family: $ff-headings;
  .main-nav {
    @include media-breakpoint-up(xl) {
      float: right;
      margin-left: 60px;
    }
    > ul {
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: 0;
      line-height: 0;

      > li {
        display: inline-block;
        position: relative;

        @include media-breakpoint-down(lg) {
          display: block;
        }

        > a, > .nolink {
          display: block;
          border-bottom: 2px solid transparent;
          padding: 9px 0 11px;
          color: #2e2e2e;
          font-size: 0.9rem;
          font-weight: 700;
          line-height: 1.55em;
          text-decoration: none;
          @include transition(all .3s);

          @include media-breakpoint-down(lg) {
            border: 0;
            padding: 13px 15px;
            color: #fff;
          }
        }

        & + li {
          margin-left: 20px;

          @include media-breakpoint-down(lg) {
            margin-left: 0;
          }
        }

        &:hover, &.active, &.active-trail {
          > a, > .nolink {
            border-color: map-get($theme-colors, primary);
            color: map-get($theme-colors, primary);
          }
        }

        @include media-breakpoint-up(xl) {
          &:hover {
            > ul {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        // Niv 2
        > ul {
          position: absolute;
          z-index: 1000;
          top: 100%;
          left: 0;
          margin: 0;
          padding: 0;
          width: 210px;
          min-width: 100%;
          background-color: map-get($theme-colors, primary);
          list-style: none;
          text-align: left;
          opacity: 0;
          visibility: hidden;
          @include transition(all .3s);

          @include media-breakpoint-down(lg) {
            display: none;
            position: relative;
            top: auto;
            left: auto;
            width: 100%;
            background-color: transparentize(#fff,0.95);
            text-align: center;
            opacity: 1;
            visibility: visible;
          }

          > li {
            > a, > .nolink {
              display: block;
              padding: 5px 15px;
              color: #fff;
              font-size: 0.9rem;
              font-weight: 400;
              line-height: 1.55em;
              text-decoration: none;
              @include transition(all .3s);
              @include media-breakpoint-down(lg) {
                padding-top: 13px;
                padding-bottom: 13px;
              }
            }

            & + li {
              border-top: 1px solid transparentize(#fff,0.85);
              @include media-breakpoint-down(lg) {
                border: 0;
              }
            }

            &:hover, &.active {
              > a, > .nolink {
                background-color: darken(map-get($theme-colors, primary), 15%);
                @include media-breakpoint-down(lg) {
                  background-color: transparent;
                  color: map-get($theme-colors, primary);
                }
              }
            }
          }
        }
        &:nth-last-child(1), &:nth-last-child(2) {
          > ul {
            left: auto;
            right: 0;
          }
        }
      }
    }

    @include media-breakpoint-down(lg) {
      > .sublvl {
        > li {
          &.expanded {
            position: relative;
            overflow: hidden;
          }
          .expand {
            display: block;
            position: absolute;
            z-index: 5;
            top: 0;
            right: 0;
            width: 48px;
            height: 48px;
            background-color: rgba(255,255,255, .1);
            cursor: pointer;
            &:before {
              content: '+';
              display: block;
              position: absolute;
              top: 50%;
              margin-top: -12px;
              width: 100%;
              color: #fff;
              font-size: 24px;
              line-height: 1em;
              text-align: center;
            }
          }
          &.open {
            .expand {
              &:before {
                content: '-';
              }
            }
            > ul {
              display: block;
            }
          }
        }
      }
    }
  }
  .secondary-nav {
    @include media-breakpoint-up(xl) {
      position: absolute;
      top: 20px;
      right: 215px;
    }
    @include media-breakpoint-down(lg) {
      margin-top: 30px;
    }
    > ul {
      float: left;
      margin: 0;
      padding: 2px 0;
      list-style: none;
      font-size: 0;
      line-height: 0;

      @include media-breakpoint-down(lg) {
        float: none;
      }

      > li {
        display: inline-block;
        vertical-align: middle;
        position: relative;

        @include media-breakpoint-down(lg) {
          display: block;
        }

        > a, > .nolink {
          display: inline-block;
          vertical-align: middle;
          padding: 0;
          color: #989898;
          font-size: 0.8rem;
          font-weight: 400;
          line-height: 1.75em;
          text-decoration: none;
          @include transition(all .3s);
          @include media-breakpoint-down(lg) {
            display: block;
            padding: 13px 15px;
          }
        }
        & + li {
          @include media-breakpoint-up(xl) {
            &:before {
              content: "|";
              display: inline-block;
              vertical-align: middle;
              padding: 0 5px;
              color: #989898;
              font-size: 0.8rem;
              font-weight: 400;
              line-height: 1.75em;
            }
          }
        }
        &:hover, &.active, &.active-trail {
          > a, > .nolink {
            color: map-get($theme-colors, primary);
          }
        }

        @include media-breakpoint-up(xl) {
          &:hover {
            > ul {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        // Niv 2
        > ul {
          position: absolute;
          z-index: 1000;
          top: 100%;
          left: 0;
          margin: 0;
          padding: 0;
          width: 210px;
          min-width: 100%;
          background-color: #989898;
          list-style: none;
          text-align: left;
          opacity: 0;
          visibility: hidden;
          @include transition(all .3s);

          @include media-breakpoint-down(lg) {
            display: none;
            position: relative;
            top: auto;
            left: auto;
            width: 100%;
            background-color: transparentize(#fff,0.95);
            text-align: center;
            opacity: 1;
            visibility: visible;
          }

          > li {
            > a, > .nolink {
              display: block;
              padding: 0 15px;
              color: #fff;
              font-size: 0.8rem;
              font-weight: 400;
              line-height: 1.75em;
              text-decoration: none;
              @include transition(all .3s);
              @include media-breakpoint-down(lg) {
                padding-top: 13px;
                padding-bottom: 13px;
                color: #989898;
              }
            }

            &:hover, &.active {
              > a, > .nolink {
                background-color: map-get($theme-colors, primary);
                @include media-breakpoint-down(lg) {
                  background-color: transparent;
                  color: map-get($theme-colors, primary);
                }
              }
            }
          }
        }
      }
    }

    @include media-breakpoint-down(lg) {
      > .sublvl {
        > li {
          &.expanded {
            position: relative;
            overflow: hidden;
          }
          .expand {
            display: block;
            position: absolute;
            z-index: 5;
            top: 0;
            right: 0;
            width: 48px;
            height: 48px;
            background-color: rgba(255,255,255, .1);
            cursor: pointer;
            &:before {
              content: '+';
              display: block;
              position: absolute;
              top: 50%;
              margin-top: -12px;
              width: 100%;
              color: #989898;
              font-size: 24px;
              line-height: 1em;
              text-align: center;
            }
          }
          &.open {
            .expand {
              &:before {
                content: '-';
              }
            }
            > ul {
              display: block;
            }
          }
        }
      }
    }
  }

  .cta-mobile {
    display: none;
    font-size: 0;
    @include media-breakpoint-down(lg) {
      display: block;
    }
    a {
      display: inline-block;
      margin: 5px;
      padding: 7px 12px;
      max-width: calc(100% - 10px);
      border-radius: 4px;
      color: #fff;
      font-size: 0;
      text-decoration: none;
      img, span {
        display: inline-block;
        vertical-align: middle;
      }
      span {
        padding-left: 15px;
        font-size: 0.9rem;
      }
      &:nth-child(1) {
        background-color: map-get($theme-colors, primary);
        color: #fff;
        &:hover {
          background-color: darken(map-get($theme-colors, primary), 10%);
          color: #fff;
        }
      }
      &:nth-child(2) {
        background-color: map-get($theme-colors, secondary);
        color: #fff;
        &:hover {
          background-color: darken(map-get($theme-colors, secondary), 10%);
          color: #fff;
        }
      }
    }
  }
}

.lang {
  float: left;
  position: relative;
  margin-left: 30px;
  font-family: $ff-headings;
  color: #989898;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.75em;
  text-transform: uppercase;
  @include media-breakpoint-down(lg) {
    display: inline-block;
    float: none;
    margin: 30px 0;
  }
  .label {
    display: inline-block;
    padding: 2px 0;
    cursor: pointer;
    @include transition(all .3s);
    @include media-breakpoint-down(lg) {
      padding: 13px 17px;
      background-color: #fff;
    }
    .fa {
      margin-left: 3px;
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
    &:hover {
      color: map-get($theme-colors, primary);
    }
  }
  ul {
    display: none;
    position: absolute;
    z-index: 5;
    top: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #fff;
    @include media-breakpoint-down(lg) {
      display: inline-block !important;
      position: relative;
      top: auto;
      left: auto;
      background-color: transparent;
    }
    li {
      @include media-breakpoint-down(lg) {
        display: inline-block;
      }
      a {
        padding: 0 5px;
        color: #989898;
        text-decoration: none;
        @include transition(all .3s);
        @include media-breakpoint-down(lg) {
          display: block;
          padding: 13px 17px;
        }
        &:hover {
          color: map-get($theme-colors, primary);
        }
      }
    }
  }
}

.top {
  float: right;
  padding-top: 20px;
  font-family: $ff-headings;
  color: #989898;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.75em;

  @include media-breakpoint-down(xs) {
    float: none;
    padding-top: 0;
  }
  .tel, .social {
    float: left;
  }
  .tel {
    margin-left: 30px;
    @include media-breakpoint-down(lg) {
      margin-left: 15px;
    }
    @include media-breakpoint-down(xs) {
      margin-left: 0;
    }
    a {
      display: inline-block;
      padding: 2px 0;
      color: map-get($theme-colors, primary);
      text-decoration: none;
      @include media-breakpoint-down(lg) {
        padding-top: 13px;
        padding-bottom: 13px;
      }
      @include media-breakpoint-down(sm) {
        padding-left: 19px;
        padding-right: 19px;
      }
      .fa {
        margin-right: 5px;
        @include media-breakpoint-down(sm) {
          margin: 0;
        }
      }
      span {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .social {
    margin-left: 10px;
    font-size: 0;
    @include media-breakpoint-down(lg) {
      margin-left: 15px;
    }
    @include media-breakpoint-down(xs) {
      margin-left: 0;
    }
    a {
      display: inline-block;
      vertical-align: middle;
      text-decoration: none;
      & + a {
        margin-left: 5px;
      }
      .fa {
        display: block;
        border: 1px solid map-get($theme-colors, blue);
        border-radius: 50%;
        width: 26px;
        height: 26px;
        background-color: #fff;
        color: map-get($theme-colors, blue);
        font-size: 0.9rem;
        line-height: 24px;
        text-align: center;
        @include transition(all .3s);
      }
      @include media-breakpoint-down(lg) {
        padding: 11px;
      }
      &:hover {
        .fa {
          background-color: map-get($theme-colors, blue);
          color: #fff;
        }
      }
    }
  }
}

.trigger-menu {
  display: block;
  position: relative;
  z-index: 5;
  float: right;
  margin-left: 15px;
  padding: 14px 12px 15px;
  cursor: pointer;
  @include transition(all .5s ease-out);

  @include media-breakpoint-down(xs) {
    margin-left: 0;
  }

  @include media-breakpoint-up(xl) {
    display: none;
  }
  .inner {
    position: relative;
    width: 24px;
    height: 19px;
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  span {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #989898;
    opacity: 1;
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    @include transition(all .25s ease-out);

    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2), &:nth-child(3) {
      top: 8px;
    }
    &:nth-child(4) {
      top: 16px;
    }
  }
  &.open {
    z-index: 10;
    @include media-breakpoint-down(xs) {
      top: -60px;
    }
    span {
      background-color: #fff;
      &:nth-child(1) {
        top: 8px;
        left: 50%;
        width: 0%;
      }
      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        top: 8px;
        left: 50%;
        width: 0%;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  nav#nav {
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 75px 0;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    background-color: transparentize(#2e2e2e,0.02);
    overflow-x: auto;
    overflow-y: scroll;
    @include transition(all .35s);
  }
}

body.open-menu {
  @include media-breakpoint-down(lg) {
    height: 100%;
    overflow: hidden;
    nav#nav {
      visibility: visible;
      opacity: 1;
    }
  }
}


body.mobile {
  #nav {
    .main-nav {
      float: none;
      margin-left: 0;
      > ul {
        > li {
          display: block;
          > a, > .nolink {
            border: 0;
            padding: 13px 15px;
            color: #fff;
          }
          & + li {
            margin-left: 0;
          }
          > ul {
            display: none;
            position: relative;
            top: auto;
            left: auto;
            width: 100%;
            background-color: transparentize(#fff,0.95);
            text-align: center;
            opacity: 1;
            visibility: visible;
            > li {
              > a, > .nolink {
                padding-top: 13px;
                padding-bottom: 13px;
              }
              & + li {
                border: 0;
              }
              &:hover, &.active {
                > a, > .nolink {
                  background-color: transparent;
                  color: map-get($theme-colors, primary);
                }
              }
            }
          }
        }
      }
      > .sublvl {
        > li {
          &.expanded {
            position: relative;
            overflow: hidden;
          }
          .expand {
            display: block;
            position: absolute;
            z-index: 5;
            top: 0;
            right: 0;
            width: 48px;
            height: 48px;
            background-color: rgba(255,255,255, .1);
            cursor: pointer;
            &:before {
              content: '+';
              display: block;
              position: absolute;
              top: 50%;
              margin-top: -12px;
              width: 100%;
              color: #fff;
              font-size: 24px;
              line-height: 1em;
              text-align: center;
            }
          }
          &.open {
            .expand {
              &:before {
                content: '-';
              }
            }
            > ul {
              display: block;
            }
          }
        }
      }
    }
    .secondary-nav {
      position: relative;
      top: auto;
      right: auto;
      margin-top: 30px;
      > ul {
        float: none;
        > li {
          display: block;
          > a, > .nolink {
            display: block;
            padding: 13px 15px;
          }
          & + li {
            &:before {
              display: none;
            }
          }
          > ul {
            display: none;
            position: relative;
            top: auto;
            left: auto;
            width: 100%;
            background-color: transparentize(#fff,0.95);
            text-align: center;
            opacity: 1;
            visibility: visible;
            > li {
              > a, > .nolink {
                padding-top: 13px;
                padding-bottom: 13px;
                color: #989898;
              }
              &:hover, &.active {
                > a, > .nolink {
                  background-color: transparent;
                  color: map-get($theme-colors, primary);
                }
              }
            }
          }
        }
      }
      > .sublvl {
        > li {
          &.expanded {
            position: relative;
            overflow: hidden;
          }
          .expand {
            display: block;
            position: absolute;
            z-index: 5;
            top: 0;
            right: 0;
            width: 48px;
            height: 48px;
            background-color: rgba(255,255,255, .1);
            cursor: pointer;
            &:before {
              content: '+';
              display: block;
              position: absolute;
              top: 50%;
              margin-top: -12px;
              width: 100%;
              color: #989898;
              font-size: 24px;
              line-height: 1em;
              text-align: center;
            }
          }
          &.open {
            .expand {
              &:before {
                content: '-';
              }
            }
            > ul {
              display: block;
            }
          }
        }
      }
    }

    .cta-mobile {
      display: block;
    }
  }

  .lang {
    display: inline-block;
    float: none;
    margin: 30px 0;
    .label {
      padding: 13px 17px;
      background-color: #fff;
      .fa {
        display: none;
      }
    }
    ul {
      display: inline-block !important;
      position: relative;
      top: auto;
      left: auto;
      background-color: transparent;
      li {
        display: inline-block;
        a {
          display: block;
          padding: 13px 17px;
        }
      }
    }
  }

  .top {
    .tel {
      margin-left: 15px;
      @include media-breakpoint-down(xs) {
        margin-left: 0;
      }
      a {
        padding-top: 13px;
        padding-bottom: 13px;
        @include media-breakpoint-down(sm) {
          padding-left: 19px;
          padding-right: 19px;
        }
      }
    }
    .social {
      margin-left: 15px;
      @include media-breakpoint-down(xs) {
        margin-left: 0;
      }
      a {
        padding: 11px;
      }
    }
  }

  .trigger-menu {
    display: block;
  }

  nav#nav {
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 75px 0;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    background-color: transparentize(#2e2e2e,0.02);
    overflow-x: auto;
    overflow-y: scroll;
    @include transition(all .35s);
  }

  &.open-menu {
    height: 100%;
    overflow: hidden;
    nav#nav {
      visibility: visible;
      opacity: 1;
    }
  }
}