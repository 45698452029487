.paragraphs-avantages {
  padding-top: 10px;
  padding-bottom: 35px;
  .avantages {
    padding: 40px 50px 45px;
    background-color: map-get($theme-colors, primary);
    color: #fff;
    @include media-breakpoint-down(md) {
      padding-left: 45px;
      padding-right: 45px;
    }
    @include media-breakpoint-down(sm) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @include media-breakpoint-down(xs) {
      padding-left: 15px;
      padding-right: 15px;
    }
    h2 {
      margin: 0 0 30px;
      color: #fff;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        position: relative;
        padding-left: 20px;
        &:before {
          content: "\f00c";
          position: absolute;
          top: 0;
          left: 0;
          font-family: "FontAwesome";
        }
      }
    }
  }
}
