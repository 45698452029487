// Variables
//

//
// Color system
//

$theme-colors: (
  primary: #f39200,
  secondary: #009e40,
  blue: #0b4f8a,
  darkblue: #185d78,
  regularblue: #002c5a,
  lightblue: #0195d6,
  orange: #fb7215,
  success: #28a745,
  info: #17a2b8,
  warning: #ffc107,
  danger: #dc3545,
  light: #f8f9fa,
  dark: #343a40,
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 990px,
  xl: 1200px,
);

// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$ff-headings: "Roboto", sans-serif;
$ff-body: "Lato", sans-serif;

$font-family-sans-serif: $ff-body;
