.item-reference {
  display: block;
  position: relative;
  margin: 20px 0;
  background-color: #000;
  text-decoration: none;
  overflow: hidden;
  @include transition(all .3s);
  &:after {
    content: "";
    position: absolute;
    z-index: 5;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
    opacity: .6;
    opacity: 0;
    @include transition(all .3s);
  }
  .img-wrapper {
    // opacity: .7;
    @include transition(all .3s);
  }
  .content {
    position: absolute;
    z-index: 10;
    bottom: -40px;
    left: 0;
    padding: 0 15px;
    width: 100%;
    text-align: center;
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    @include transition(all .3s);
  }
  .text {
    padding: 30px 0 45px;
    @include transition(all .3s);
  }
  .location {
    margin: 0;
    color: #fff;
    font-family: $ff-headings;
    font-size: 0.9rem;
    font-weight: 400;
    letter-spacing: .5px;
    line-height: normal;
    text-transform: uppercase;
  }
  h3 {
    margin: 0;
    color: #fff;
    font-size: 1.15rem;
    font-weight: 400;
    line-height: normal;
  }
  .btn {
    padding: 10px 15px;
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: .5px;
    line-height: normal;
    text-transform: uppercase;
    opacity: 0;
    pointer-events: none;
    @include transition(all .3s);
  }
  &:hover {
    background-color: map-get($theme-colors, primary);
    &:after {
      opacity: 0;
    }
    .img-wrapper {
      opacity: .2;
    }
    .content {
      bottom: 50%;
      -ms-transform: translateY(50%);
      -webkit-transform: translateY(50%);
      transform: translateY(50%);
    }
    .text {
      padding-top: 0;
      padding-bottom: 15px;
    }
    .btn {
      background-color: #fff;
      border-color: #fff;
      color: map-get($theme-colors, primary);
      opacity: 1;
    }
  }
  &.content-center {
    &:after {
      display: none;
    }
    .content {
      bottom: 50%;
      -ms-transform: translateY(50%);
      -webkit-transform: translateY(50%);
      transform: translateY(50%);
    }
    .btn {
      opacity: 1;
    }
  }
}
