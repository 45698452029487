// -------------------------------------------------------------
//   Main footer
//   Footer styles
// -------------------------------------------------------------

.main-footer {
  color: #868686;
  font-family: $ff-headings;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.55em;
  .wrapper {
    border-top: 1px solid #e6e6e6;
    padding-top: 70px;
    padding-bottom: 50px;
  }
  h6 {
    margin: 0;
    color: #474747;
    font-size: 0.9rem;
    font-weight: 700;
    line-height: 1.55em;
  }
  a:not([class*="btn"]) {
    color: map-get($theme-colors, primary);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .content {
    margin: 10px 0 0;
    @include media-breakpoint-down(xs) {
      margin-top: 30px;
    }
  }
  .copyright {
    @include media-breakpoint-down(md) {
      margin-top: 30px;
    }
    a:not([class*="btn"]) {
      color: #868686;
    }
  }
  .social {
    margin: 5px 0 25px;
    font-size: 1.15rem;
    line-height: 1.2em;
    .fa {
      margin-left: 5px;
      font-size: 1.5rem;
      line-height: 0.9em;
    }
    .linkedin {
      color: #0077b5 !important;
    }
    .twitter {
      color: #1DA1F2 !important;
    }
  }
}

body.front, body.node-type-page {
  .main-footer {
    .wrapper {
      border-top: 0;
    }
  }
}
