.item-produit {
  display: block;
  position: relative;
  margin: 10px 0;
  border: 1px solid #e6e6e6;
  padding: 35px 40px;
  height: 310px;
  background-color: #fff;
  text-decoration: none;
  @include transition(all .3s);
  @include media-breakpoint-down(lg) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include media-breakpoint-down(xs) {
    padding-left: 15px;
    padding-right: 15px;
  }
  .img-wrapper {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    img {
      position: absolute;
      bottom: 0;
      right: 0;
      // width: auto;
      // height: 100%;
      width: 50%;
      height: auto;
    }
  }
  .content {
    max-height: calc(100% - 45px);
    color: #888;
    font-family: $ff-headings;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.35em;
    overflow: hidden;
  }
  .desc {
    position: relative;
    z-index: 5;
    max-width: 180px;
  }
  h3 {
    margin: 0;
    color: map-get($theme-colors, secondary);
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1em;
    span {
      color: map-get($theme-colors, primary);
    }
  }
  h4 {
    margin: 0 0 5px;
    color: #2b2b2b;
    font-size: 1rem;
    line-height: 1.5em;
  }
  p {
    margin: 0;
  }
  .btn {
    position: absolute;
    z-index: 5;
    bottom: 35px;
    left: 40px;
    padding: 6px 10px;
    font-size: 0.9rem;
    pointer-events: none;
    @include media-breakpoint-down(lg) {
      left: 20px;
    }
    @include media-breakpoint-down(xs) {
      left: 15px;
    }
  }
  &:hover {
    border-color: #fff;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  }
}
