.page-blog {
  background-color: #f8f8f8;
  .view {
    @extend .container;
    padding-top: 25px;
    padding-bottom: 55px;
  }
  .view-content {
    @include make-row();
    margin-right: -5px;
    margin-left: -5px;
    .views-row {
      @include make-col-ready();
      @include make-col(3);
      padding-right: 5px;
      padding-left: 5px;
      @include media-breakpoint-down(md) {
        @include make-col(6);
      }
      @include media-breakpoint-down(xs) {
        @include make-col(12);
      }
    }
  }
}
