.paragraphs-evidence {
  position: relative;
  background-color: #f8f8f8;
  .img-wrapper {
    @include media-breakpoint-up(md) {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
    @include media-breakpoint-down(sm) {
      padding-bottom: 56.25%;
    }
  }
  .content {
    position: relative;
    left: 50%;
    padding: 60px 80px 100px;
    max-width: 515px;
    color: #838383;
    @include media-breakpoint-down(lg) {
      padding-left: 60px;
      padding-right: 60px;
    }
    @include media-breakpoint-down(md) {
      padding-left: 15px;
      padding-right: 15px;
    }
    @include media-breakpoint-down(sm) {
      left: auto;
      padding-left: 0;
      padding-right: 0;
    }
    .title {
      color: map-get($theme-colors, secondary);
    }
  }
  @include media-breakpoint-up(md) {
    &.posDroite {
      .img-wrapper {
        left: 50%;
      }
      .content {
        left: auto;
      }
    }
  }
}
